<template>
  <div :class="{ 'has-error': opportunityStatusError }">
    <div class="row">
      <div class="col">
        <label class="control-label mb-1" for="opportunityStatus">{{ $t('commons.status') }}</label>
      </div>
      <div class="col">
        <select class="ccmc_input form-control" id="opportunityStatus"
                :value="value"
                v-model="selectedStatus"
                @change="updateStatus()">
          <option v-for="status of statusList" :key="status.value" :value="status.value">{{ $t(status.label) }}</option>
        </select>
        <span v-if="opportunityStatusError" class="help-block">{{ $t(opportunityStatusError) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from '@/validations'

let availableStatus = [{"value":"PENDING_APPROVAL","label":"opportunityStatus.PENDING_APPROVAL"},
                       {"value":"APPROVED","label":"opportunityStatus.APPROVED"},
                       {"value":"REJECTED","label":"opportunityStatus.REJECTED"},
                       {"value":"MODIFIED","label":"opportunityStatus.MODIFIED"},
                       {"value":"SOLD","label":"opportunityStatus.SOLD"},
                       {"value": "RENEWED", "label": "opportunityStatus.RENEWED"}
                      ]


export default {
  name: 'OpportunityStatus',
  props: {
      value: String,
      attempts: {
        type: Number,
        default: 1
      }
    },
  data() {
    return {
      statusList: availableStatus,
      selectedStatus : this.value
    }
  },
  computed: {
    opportunityStatusError() {
      if ( this.attempts > 0 ) {
       if(isEmpty(this.value)) return 'error-messages.invalidStatus'
      }
      return ''
    }
  },
  methods: {
    updateStatus() {
      this.$emit('input', this.selectedStatus)
    }
  }
}

</script>