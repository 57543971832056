<template>
  <div class="container">
    <div class="white-box mt-2">
      <h2 class="mb-3">{{ $t("mainMenu.myAdvertisements") }}</h2>
      <h3>{{ $t('user.sortBy') }}</h3>
      <div class="row mb-3 gy-3">
        <div class="col-lg-4">
          <select id="statusSelect" class="form-control"
                  v-model="selectedStatus"
                  @change="getOpportunities(currentPage)">
            <option v-for="status of opportunitiesStatus" :key="status.value"
                    :value="status.value">{{ $t(status.label) }}
            </option>
          </select>
        </div>
      </div>
      <template v-if="isRequestInProgress">
        <loading></loading>
      </template>
      <template v-else>
        <template v-if="!isEditing">
          <template v-if="localPaginatedItems.items && localPaginatedItems.items.length > 0">
            <opportunity-list
                :paginatedItems="localPaginatedItems"
                :isEditableList="true"
                :sendToEdit="sendToEdit"
                :isOwner="true"
                @loadNewPageData="getNewPageData($event)"
            />
          </template>
          <template v-else>
            <not-results-found></not-results-found>
          </template>
        </template>
        <template v-else>
          <opportunity-register
              :toUpdateOpportunity="toUpdateOpportunity"
              :isEditing="isEditing"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
let statusList = [
  {"value": "PUBLIC_ONLINE", "label": "opportunityStatus.PUBLIC_ONLINE"},
  {"value": "PENDING_APPROVAL", "label": "opportunityStatus.PENDING_APPROVAL"},
  {"value": "MODIFIED", "label": "opportunityStatus.MODIFIED"},
  {"value": "REJECTED", "label": "opportunityStatus.REJECTED"},
  {"value": "SOLD", "label": "opportunityStatus.SOLD"},
  {"value": "EXPIRED", "label": "opportunityStatus.EXPIRED"}
];

// @ is an alias to /src
import {ApiService} from "@/rest.js";
import OpportunityList from "@/components/opportunity/OpportunityList.vue";
import {Opportunity, PaginatedItems} from "@/entities.js";
import OpportunityRegister from "@/components/opportunity/OpportunityRegister.vue";
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'

export default {
  name: "MyOpportunitiesView",
  components: {
    OpportunityList,
    OpportunityRegister,
    Loading,
    NotResultsFound
  },
  data() {
    return {
      localPaginatedItems: new PaginatedItems(),
      currentPage: 1,
      toUpdateOpportunity: new Opportunity(),
      isEditing: false,
      isRequestInProgress: false,
      opportunitiesStatus: statusList,
      selectedStatus: "PUBLIC_ONLINE"
    };
  },
  methods: {
    getOpportunities(pageNumber) {
      this.currentPage = pageNumber;
      this.isRequestInProgress = true;
      ApiService.GET(
          "/opportunity/protected/myOpportunities/" + this.selectedStatus + "/" + this.currentPage,
          (err, apiResponse) => {
            this.isRequestInProgress = false;
            if (!err) this.localPaginatedItems = apiResponse;
          }
      );
    },
    getNewPageData(pageNumber) {
      this.currentPage = pageNumber;

      this.getOpportunities(pageNumber);
    },
    sendToEdit(editingOpportunity) {
      this.isEditing = true;
      this.toUpdateOpportunity = editingOpportunity;
    }
  },
  created() {
    this.getOpportunities(this.currentPage);
    this.selectedStatus = "PUBLIC_ONLINE"
  }
};
</script>
