<template>

    <div
        class="opp-mini"
        :class="[{ 'opp-mini--is-compact': isCompact }, levelClass]"
    >
      <div>
        <div class="">
          <div class="opp-mini__seller">
            <span>{{ sellerTitle }}</span>
          </div>
        </div>
      </div>
      <section class="opp-mini__thumbnails">
        <div class="tcarousel opp-mini__thumbnail">
          <div
              id="cccm_miniature_thumbnail"
              class="tcarousel-wrap"
              v-on:scroll.passive="onScroll($event)"
              @click.prevent="showOpportunityDetail()"
          >
            <div
                v-for="imgid in imgUrls" :key="imgid"
                class="tcarousel-wrap-item">
              <span>
                <img alt="" class="vehicle-image" loading="lazy"
                     :src="imgid" @error="imageLoadOnError">
              </span>
            </div>
          </div>
          <button
              v-if="count > 1"
              :disabled="current === 0"
              @click="prevImg($event)"
              class="tcarousel-prev" style="display: none;">
            <img alt="prev" src="@/assets/icons/prev-min.svg">
          </button>
          <button
              v-if="count > 1"
              :disabled="current === (count - 1)"
              @click="nextImg($event)"
              class="tcarousel-next" style="display: none;">
            <img alt="next" src="@/assets/icons/next-min.svg">
          </button>
          <div v-if="count > 1" class="tcarousel-nav">
            <div
                v-for="(imgid, dotIndex) in imgUrls" :key="imgid"
                class="tcarousel-dot" :class="{ 'selected-dot': current === dotIndex }">
            </div>
          </div>
        </div>
        <div class="opp-mini__shade"></div>
        <div class="ribbon">
          <template v-if="opportunity.message">{{ opportunity.message }}</template>
        </div>
      </section>

      <section class="opp-mini__body">
        <div class="container-fluid text-start opp-mini__small-padding">
          <div class="row">
            <div class="col opp-mini__first-line">
              <span class="opp-mini__brand">{{ opportunity.brand || $t('opportunity.brand') }}</span>
              {{ ' ' }}
              <span class="opp-mini__model">  {{ formatTextModel(opportunity.model) }}</span>
              {{ ' - ' }}
              <span class="opp-mini__year">{{ opportunity.year || $t('commons.year') }}</span>
            </div>
            <div v-if="isLogged && !isImpersonalizator"
                 class="col-2 opp-mini__add-favorites">
              <em :class="isOnMyWishList ? 'bi bi-star-fill' : 'bi bi-star'"
                  @click="handleFavoriteClick()"
                  :title="$t('opportunity.addToFavorites')"></em>
            </div>
          </div>
          <div class="row">
            <div class="col opp-mini__mileage">
              <span class="opp-mini__model">{{ $t(transmissionKey) }} / {{ $t(fuelKey) }}</span>

            </div>
          </div>
          <div class="row">
            <div class="col opp-mini__mileage">
              <span>{{ mileageText }}</span>
            </div>
          </div>
          <hr class="opp-mini__hr">
          <div class="row">
            <div class="col opp-mini__price">
              {{ opportunity.currency === 'CRC' ? '₡' : '$' }}{{ opportunity.price | currency }}
            </div>
          </div>
        </div>

        <section v-if="!isCompact" class="opp-mini__footer">
          <div class="row justify-content-center">
            <div class="col mb-2">
              <a id="ccmc_miniature_sellerWhatsapp" class="btn btn-whatsapp"
                 @click="contactSellerByWhatsappAnalytics(getWhatsappNumber)"
                 :href="isPreview ?  'javascript:void(0);' : contactSellerByWhatsapp(getWhatsappNumber)"
                 :target="isPreview ? undefined : '_blank'"
                 role="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                </svg>
                {{ $t('opportunity.contactSeller') }}
              </a>

            </div>
          </div>
        </section>
      </section>



    <section>
      <div v-if="isOwner || isAdmin" class="userEdit-box">
        <template v-if="isOwner">
          <div class="row mt-1">
            <div class="col-7">
              <span class="opp-mini__model">{{ $t('commons.status') }}: </span>
              <span class="opp-mini__brand">{{ $t(actualStatus) }}</span>
            </div>
            <div class="col-5" v-if="!isSold && !isCheckedSold">
              <input
                  class="form-check-input m-1"
                  id="isSoldCheckbox"
                  type="checkbox"
                  @click="updateMyOwnOpportunityStatus()"
              />
              <label class="form-check-label" for="isSoldCheckbox">
                {{ $t('opportunityStatus.SOLD') }}
              </label>
            </div>
          </div>
        </template>
        <template v-if="isAdmin && !opportunity.preview">
          <my-opportunity-status
              v-model="opportunity.status"
              :value="opportunity.status">
          </my-opportunity-status>
          <div class="row">
            <div class="col">
              <template v-if="isStatusChanged">
                <span class="opp-mini__brand d-flex justify-content-center mt-1">{{ $t('commons.statusSaved') }} </span>
              </template>
              <template v-else>
                <button
                    type="button"
                    class="btn btn-primary mt-2"
                    @click="updateOpportunityStatus()"
                >{{ $t('commons.save') }}
                </button>
              </template>
            </div>
          </div>

        </template>
        <div class="row justify-content-center mt-1">
          <div class="col d-flex justify-content-center">
            <button
                v-if="isEditable && !isImpersonalizator"
                type="button"
                class="btn btn-success"
                @click="sendToEdit()"
            >{{ $t('commons.edit') }}
            </button>
          </div>
        </div>
        <div class="row justify-content-center mt-1">
          <div class="col d-flex justify-content-center">
            <a href="#" @click="checkStatusHistory(opportunity.id)">
              {{ $t('opportunityStatusHistory.history') }}
            </a>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
import {ApiService} from "@/rest.js";
import defaultImage from "@/assets/preview.jpg";
import {mapGetters} from "vuex";
import OpportunityStatus from "@/components/opportunity/OpportunityStatus.vue";
import MyOpportunityStatus from "@/components/opportunity/MyOpportunityStatus.vue";
import {AnalitycsData, Opportunity} from "@/entities";
import {buildWhatsappPreviewUrl, formatAmount} from "@/helper";
import {i18n} from "@/main";
import {ANALITYCS_TYPES} from "@/constants";

export default {
  name: "OpportunityMiniature",
  components: {
    OpportunityStatus,
    MyOpportunityStatus
  },
  props: {
    opportunity: {
      type: Object,
      default: () => new Opportunity()
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    isOwner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hadError: false,
      isOnMyWishList: false,
      current: 0,
      swipeEnabled: true,
      isCheckedSold: false,
      sellerTitle: '',
      isStatusChanged: false
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isLogged", "isAdmin", "isImpersonalizator"]),

    buildSellerName() {
      if (this.opportunity.user) {

        let isMerchant = (this.opportunity.user.userType && this.opportunity.user.userType === 'CARS_SELLER_MERCHANT')

        if (isMerchant) {
          if (this.opportunity.user.merchantName) {
            return this.opportunity.user.merchantName;
          } else {
            return this.$i18n.t("user.merchantName");
          }
        } else if (this.opportunity.user.name !== '') {
          return this.opportunity.user.name + ' ' + this.opportunity.user.lastName;
        } else {
          return this.userInfo.name + ' ' + this.userInfo.lastName;
        }
      }
      return this.$i18n.t("opportunity.contactName");
    },
    reducedExtraList() {
      if (this.opportunity && this.opportunity.extraList) {
        return this.opportunity.extraList.split(",").slice(0, 6);
      }

      return [];
    },
    transmissionKey() {
      let key = "manual";

      if (this.opportunity && this.opportunity.transmissionType) {
        key = this.opportunity.transmissionType.toLowerCase();
      }

      return "transmissionTypes." + key;
    },
    fuelKey() {
      let key = "gasoline";

      if (this.opportunity && this.opportunity.fuelType) {
        key = this.opportunity.fuelType.toLowerCase();
      }

      return "fuelTypes." + key;
    },
    imgUrls() {
      if (this.hadError) {
        return [defaultImage];
      }

      if (
          this.opportunity.attachmentIds &&
          this.opportunity.attachmentIds.length
      ) {
        return this.opportunity.attachmentIds.split(',').map(attachId => {
          return ApiService.getImageURL(attachId, true);
        });
      }

      return [defaultImage];
    },
    count() {
      return this.imgUrls.length;
    },
    levelClass() {
      if (this.opportunity.level === 4) {
        return "opp-mini--gold";
      } else if (this.opportunity.level === 3) {
        return "opp-mini--silver";
      } else if (this.opportunity.level === 1) {
        return "opp-mini--free";
      }

      return "";
    },
    mileageText() {
      if (this.opportunity && this.opportunity.mileage) {
        if (this.opportunity.mileageUnit) {
          return formatAmount(this.opportunity.mileage) + ' ' + this.$i18n.t('opportunity.mileageUnit.' + this.opportunity.mileageUnit);
        } else {
          return formatAmount(this.opportunity.mileage) + ' ' + this.$i18n.t('opportunity.mileageUnit.KM');
        }
      }

      return this.$i18n.t('opportunity.mileage') + ': ' + this.$i18n.t('opportunity.mileageUnit.notAvailable');
    },
    getWhatsappNumber() {
      if (this.opportunity && this.opportunity.phoneList && this.opportunity.phoneList.length > 0) {
        const phoneList = this.opportunity.phoneList;

        for (let phone of JSON.parse(phoneList)) {
          if (phone.phoneType === "WHATSAPP") return phone.phone;
        }

        return JSON.parse(phoneList)[0].phone;
      }
      return '';
    },
    isSold() {
      return this.opportunity.status === 'SOLD';
    },
    actualStatus() {
      return this.opportunity.status === 'SOLD' || this.isCheckedSold ?
          'opportunityStatus.SOLD' :
          'opportunityStatus.' + this.opportunity.status
    }
  }
  ,
  methods: {
    handleFavoriteClick() {
      if (!this.isPreview) {
        this.isOnMyWishList ? this.removeFromMyList() : this.saveToMyList()
      }
    },
    contactSellerByWhatsappAnalytics() {
      const analyticsData = new AnalitycsData();

      analyticsData.opportunityId = this.opportunity.id;
      analyticsData.clickType = ANALITYCS_TYPES.CONTACT_SELLER_BY_WHATSAPP.value;

      ApiService.POST(
          "/analytics/contactByWhatsapp",
          analyticsData,
          () => {
          }
      );
    },
    contactSellerByWhatsapp(phone) {
      return buildWhatsappPreviewUrl(phone, i18n.t("opportunity.sendWhatsAppMessage"), this.opportunity, i18n.t("domain.serverUrl"));
    },
    onScroll($e) {
      if (this.swipeEnabled) {
        const wrapper = $e.target.closest('.tcarousel').getElementsByClassName('tcarousel-wrap')[0];
        const imgWidth = wrapper.offsetWidth;
        const scroll = wrapper.scrollLeft;
        this.current = Math.floor(scroll / imgWidth);
      }
    }
    ,
    prevImg($e) {
      const wrapper = $e.target.closest('.tcarousel').getElementsByClassName('tcarousel-wrap')[0];
      const imgWidth = wrapper.offsetWidth;
      this.swipeEnabled = false;
      this.current -= 1;
      wrapper.scrollLeft = this.current * imgWidth;
    }
    ,
    nextImg($e) {
      const wrapper = $e.target.closest('.tcarousel').getElementsByClassName('tcarousel-wrap')[0];
      const imgWidth = wrapper.offsetWidth;
      this.swipeEnabled = false;
      this.current += 1;
      wrapper.scrollLeft = this.current * imgWidth;
    }
    ,
    imageLoadOnError() {
      this.hadError = true;
    }
    ,
    showOpportunityDetail() {
      if (!this.isPreview) {
        try {
          localStorage.setItem('scroll', document.documentElement.scrollTop);
        } catch (err) {

        }

        this.$router.push({
          path: "/opportunity/" + this.opportunity.id,
          params: {
            opportunity: this.opportunity,
            opportunityId: this.opportunityId
          }
        })
      }
    }
    ,
    sendToEdit() {
      this.$router.push({
        name: 'opportunityUpdateView',
        params: {opportunityId: this.opportunity.id}
      })
    }
    ,
    updateOpportunityStatus() {
      ApiService.PUT(
          "/opportunity/protected/updateStatus",
          {id: this.opportunity.id, newStatus: this.opportunity.status},
          (err, apiResponse) => {
            this.isStatusChanged = true;
            if (err) {
            }
          }
      );
    },
    updateMyOwnOpportunityStatus() {
      ApiService.PUT(
          "/opportunity/protected/myOpportunityStatusUpdate",
          {id: this.opportunity.id, newStatus: 'SOLD'},
          (err, apiResponse) => {
            this.isCheckedSold = true;
            if (err) {
            }
          }
      );
    },
    checkStatusHistory(opportunityId) {
      this.$router.push({
        name: 'opportunityStatusHistory',
        params: {opportunityId: opportunityId}
      })
    }
    ,
    saveToMyList() {
      ApiService.POST(
          "/users/protected/myWishList",
          this.opportunity,
          (err, apiResponse) => {
            if (!err) {
              this.isOnMyWishList = true;
            }
          }
      );
    }
    ,
    removeFromMyList() {
      ApiService.DELETE(
          "/users/protected/myWishList",
          this.opportunity,
          (err, apiResponse) => {
            if (!err) {
              this.isOnMyWishList = false;
            }
          }
      );
    }
    ,
    formatTextModel: function (model) {
      if (!model) return '';
      model = model.toLowerCase();
      return model.charAt(0).toUpperCase() + model.slice(1);
    }
  }
  ,
  mounted() {
    this.sellerTitle = this.buildSellerName;
  }
}
</script>