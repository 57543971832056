<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped text-nowrap w-auto">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" @click="sortBy('email')">{{ $t('commons.email') }}</th>
          <th scope="col" @click="sortBy('name')">{{ $t('user.name') }}</th>
          <th scope="col" @click="sortBy('lastName')">{{ $t('user.lastName') }}</th>
          <th scope="col" @click="sortBy('userType')">{{ $t('commons.type') }}</th>
          <th scope="col" @click="sortBy('userRole')">{{ $t('user.role') }}</th>
          <th scope="col" @click="sortBy('status')">{{ $t('commons.status') }}</th>
          <th scope="col" @click="sortBy('dateCreated')">{{ $t('user.dateCreated') }}</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in sortedUsers" :key="user.id"
            @click="selectRow(index)"
            :class="{ 'selected-row': selectedRowIndex === index }">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ user.email }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.lastName }} {{ user.lastName2 }}</td>
          <td>{{ getUserType(user.userType) }}</td>
          <td>{{ getRolePrefix($t('user.roles.' + user.role)) }}</td>
          <td>{{ getStatusAbbreviation(user.status) }}</td>
          <td>{{ formattedDate(user.dateCreated) }}</td>
          <td>
            <a href="#" @click="changeSelectedUser(user.id)">{{ $t('commons.edit') }}</a>
          </td>
          <td class="align-text-top text-center">
            <a href="#" @click="goToUserOpportunities(user.id)">
              {{ $t('user.maintenance.ownOpportunities') }}
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {formatIso8601ToShorTime} from "@/helper";

export default {
  name: 'UsersList',
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortKey: '',
      sortOrders: {},
      selectedRowIndex: null
    }
  },
  computed: {
    sortedUsers() {
      return this.users.sort((a, b) => {
        let modifier = 1;
        if (this.sortOrders[this.sortKey] === 'desc') {
          modifier = -1;
        }
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return modifier;
        return 0;
      });
    }
  },
  methods: {
    changeSelectedUser(id) {
      this.$router.push({
        name: 'adminUserMaintenance',
        params: {userId: id}
      })
    },
    goToUserOpportunities(userId) {
      this.$router.push({
        name: 'userOpportunitiesViewAdmin',
        params: {userId: userId}
      })
    },
    formattedDate(dateCreated) {
      return formatIso8601ToShorTime(dateCreated);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] === 'asc' ? 'desc' : 'asc';
    },
    selectRow(index) {
      this.selectedRowIndex = index;
    },
    getRolePrefix(role) {
      return role.substring(0, 3);
    },
    getStatusAbbreviation(status) {
      if (status === 'ACTIVE') {
        return "A";
      } else if (status === 'NOT_CONFIRMED') {
        return "NC";
      } else if (status === 'BLOCKED_BY_FAILS') {
        return "B";
      }
      return ''
    },
    getUserType(type) {
      if (type === 'PERSON') {
        return "I";
      } else if (type === 'CARS_SELLER_MERCHANT') {
        return "C";
      }
      return ''
    }
  }
}
</script>